<template>
  <div id="app">
    <div class="app__preloader" v-if="this.$store.state.showPreloader || this.$store.state.syncCompleted === false"
      style="height: 100vh; width: 100vw; position: fixed; top: 0; left: 0; z-index: 999999;">
      <Loader />
    </div>
    <div :class="{ overlay: $store.state.isOpenBurgerMenu || $store.state.isOpenUserDropMenu }"></div>
    <BurgerMenu />
    <UserDropMenu />
    <SupportChat />
    <router-view />
  </div>
</template>

<script>
import '@/scss/_reset.scss'
import '@/scss/fonts/font.css'
import '@/scss/_main.scss'

import BurgerMenu from '@/components/BurgerMenu/BurgerMenu'
import UserDropMenu from '@/components/UserDropMenu/UserDropMenu'
import Loader from '@/components/LoaderBar/LoaderBar'

import SupportChat from '@/components/SupportChat/SupportChat'

const currencyMap = {
  rub: '₽',
  usd: '$',
  eur: '€'
}

const languagesMap = [
  'en',
  'ru',
  'tr',
  'fr',
  'br',
  'pt',
  'es',
  'de'
]
export default {
  name: 'App',
  components: {
    BurgerMenu,
    UserDropMenu,
    Loader,
    SupportChat
  },
  metaInfo () {
    return {
      meta: [
        {
          name: 'description',
          content: this.$i18n.t('app.description') // здесь используется ваш ключ перевода для описания
        }
      ],
      link: [
        { rel: 'canonical', href: this.canonicalHref }
      ]
    }
  },
  provide: {
    currencyMap
  },
  data () {
    return {
      isDeveloper: false
    }
  },
  async created () {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        this.$store.state.showPreloader = false
      }
    }

    // window.enableDevMode = () => {
    //   this.isDeveloper = true
    // }

    // Убрали динамическое переключение локализации и валюты сайта
    // await this.getCountry()
  },
  computed: {
    canonicalHref () {
      const excludedRoutes = ['Apple', 'Transfer', 'NotFound']
      const path = excludedRoutes.includes(this.$route.name) ? '/' : this.$route.path

      return window.location.origin + path
    }
  },
  methods: {
    async getCountry () {
      let language = localStorage.getItem('language')
      let currency = localStorage.getItem('currency')

      fetch('https://users.backend-pier.com/current-country')
        .then(res => res.json())
        .then(data => {
          if (!language) {
            language = data.country.toLowerCase()

            if (!languagesMap.includes(language)) language = 'en'
            if (language === 'pt') language = 'br'
          }

          if (!currency) {
            const allowedCurrencies = {
              en: 'eur',
              ru: 'rub'
            }
            currency = allowedCurrencies[language] || 'usd'
          }

          this.$store.commit('setLanguage', language)
          this.$store.commit('setCurrency', currency)
          this.$i18n.locale = language
          this.$store.state.ipCountry = language
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
