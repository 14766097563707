<template>
  <div class="home">
    <h1 class="seo-title">Купить робуксы</h1>
    <h2 class="seo-title">Купить робуксы легко!</h2>
    <div class="auth"><Auth /></div>
    <div class="change-password" v-if="$store.state.change_password"><ChangePassword /></div>
    <div class="wrapper">
      <div class="f-block">
        <Navigation />
        <Slider />
      </div>
    </div>
    <Faq />
    <Help />
    <Footer />
  </div>
</template>

<script>
import './home.scss'

import Navigation from '@/components/Navigation/Navigation'
import Slider from '@/components/Slider/Slider'
import Faq from '@/components/FAQ/Faq'
import Help from '@/components/Help/Help'
import Footer from '@/components/Footer/Footer'
import Auth from '@/components/Auth/Auth'
import ChangePassword from '@/components/ChangePassword/ChangePassword'

export default {
  name: 'Home',
  components: {
    Navigation,
    Slider,
    Faq,
    Help,
    Footer,
    Auth,
    ChangePassword
  }
}
</script>
