import Vue from 'vue'
import VueRouter from 'vue-router'
import cookie from 'vue-cookies'

import Home from '@/views/Home/Home.vue'
import History from '@/views/History/History.vue'
import Transfer from '@/views/Transfer/Transfer'
import Fastpass from '@/views/FastPass/FastPass'
import Payments from '@/views/Payments/Payments.vue'
import TermsOfUse from '@/views/TermsOfUse/TermsOfUse.vue'
import VKAuth from '@/views/VKAuth/VKAuth'
import Referal from '@/views/Referal/Referal'
import Apple from '@/views/Apple/Apple.vue'
import Contacts from '@/views/Contacts/Contacts'
import BuyMethod from '@/views/BuyMethod/BuyMethod'
import Premium from '@/views/Premium/Premium.vue'
import LogPass from '@/views/LogPass/LogPass'
import PrivacyPolicy from '@/views/PrivacyPolicy/PrivacyPolicy'
import NotFound from '@/views/NotFound/NotFound.vue'

import store from '@/store/index'
import { i18n } from '@/i18n/index'

Vue.use(VueRouter)

const DEFAULT_TITLE = i18n.t('app.title')
const DEFAULT_DESCRIPTION = i18n.t('app.description')

const routes = [
  {
    path: '/code',
    name: 'Apple',
    component: Apple,
    meta: {
      auth: -1
    }
  },
  {
    path: '/vkauth',
    name: 'VKAuth',
    component: VKAuth,
    props: (route) => ({ query: route.query.q }),
    meta: {
      auth: -1
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: -1,
      description: i18n.t('app.description')
    }
  },
  {
    path: '/ref/:code',
    name: 'Referal',
    component: Referal,
    meta: {
      auth: -1,
      title: `${i18n.t('app.title')}`,
      description: `${i18n.t('app.description')}`
    }
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    meta: {
      auth: 1,
      title: `${i18n.t('app.history.title')}`,
      description: `${i18n.t('app.history.description')}`
    }
  },
  {
    path: '/methods',
    name: 'Methods',
    component: BuyMethod,
    meta: {
      auth: -1,
      title: `${DEFAULT_TITLE} - ${i18n.t('app.titlePostfix.methods')}`
    },
    beforeEnter (to, from, next) {
      next('/transfer')
    }
  },
  {
    path: '/transfer',
    name: 'Transfer',
    component: Transfer,
    meta: {
      auth: -1,
      title: `${i18n.t('app.transfer.title')}`,
      description: `${i18n.t('app.transfer.description')}`
    }
  },
  {
    path: '/fastpass',
    name: 'Fastpass',
    component: Fastpass,
    meta: {
      auth: -1,
      title: `${DEFAULT_TITLE} - ${i18n.t('app.titlePostfix.transfer')}`
    },
    beforeEnter (to, from, next) {
      if (store.state.methods.isFastPassSalesAvailable) next()
      else next('/methods')
    }
  },
  {
    path: '/logpass',
    name: 'LogPass',
    component: LogPass,
    meta: {
      auth: -1,
      title: `${DEFAULT_TITLE} - ${i18n.t('app.titlePostfix.logpass')}`
    },
    beforeEnter (to, from, next) {
      if (store.state.methods.isLogPassSalesAvailable) next()
      else next('/methods')
    }
  },
  {
    path: '/premium',
    name: 'Premium',
    component: Premium,
    meta: {
      auth: -1,
      title: `${DEFAULT_TITLE} - ${i18n.t('app.titlePostfix.premium')}`
    },
    beforeEnter (to, from, next) {
      if (store.state.methods.isPremiumSalesAvailable) next()
      else next('/methods')
    }
  },
  {
    path: '/payment',
    name: 'Payments',
    component: Payments,
    meta: {
      auth: 1,
      title: `${i18n.t('app.payment.title')}`,
      description: `${i18n.t('app.payment.description')}`
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyPolicy,
    meta: {
      auth: -1,
      title: i18n.t('app.privacy.title'),
      description: i18n.t('app.privacy.description')
    }
  },
  {
    path: '/termsofuse',
    name: 'TermsOfUse',
    component: TermsOfUse,
    meta: {
      auth: -1,
      title: i18n.t('app.termsofuse.title'),
      description: i18n.t('app.termsofuse.description')
    }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: {
      auth: -1,
      title: i18n.t('app.contacts.title'),
      description: i18n.t('app.contacts.description')
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: {
      auth: -1,
      title: i18n.t('app.notFound.title'),
      description: i18n.t('app.notFound.description')
    },
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // if (localStorage.getItem('apple') === null && to.name !== 'Apple') {
  //   return next('/code')
  // }
  // Set the meta description
  const metaDescription = document.querySelector('meta[name="description"]')
  if (metaDescription) {
    metaDescription.setAttribute('content', to.meta.description || DEFAULT_DESCRIPTION)
  }

  const accessToken = cookie.get('access_token') || store.state.account.accessToken

  if (!cookie.get('access_token')) {
    store.commit('removeAccountData')
  }
  if (accessToken && !store.state.account.userId) {
    store.state.syncCompleted = false
    store.dispatch('authorization', accessToken)
  } else {
    store.state.syncCompleted = true
  }

  if (to.meta.auth === 1) {
    if (accessToken) {
      return next()
    } else {
      return next('/')
    }
  } else if (to.meta.auth === -1) {
    return next()
  }

  next('/')
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})

export default router
