<template>
  <footer class="footer">
    <div class="footer__body">
      <div class="footer__logo">
        <router-link to="/">
          <Logo />
        </router-link>
        <div class="footer__copyright">ROBUXPIER ©2018-2025</div>
      </div>
      <div class="footer__nav info">
        <h3>{{ $t('navigation.title') }}</h3>
        <div class="footer__links">
          <router-link class="link" to="/">{{ $t('navigation.home') }}</router-link>
          <router-link class="link" to="/transfer">{{ $t('navigation.buyRobux') }}</router-link>
          <router-link class="link" to="/history">{{ $t('navigation.account') }}</router-link>
        </div>
      </div>
      <div class="footer__nav">
        <h3>{{ $t('navigation.info') }}</h3>
        <div class="footer__links">
          <router-link class="link" to="/privacy">{{ $t('footer.privacy') }}</router-link>
          <router-link class="link" to="/termsofuse">{{ $t('footer.terms') }}</router-link>
          <router-link class="link" to="/contacts">{{ $t('footer.contacts') }}</router-link>
        </div>
      </div>
      <div class="footer__social">
        <div class="footer__social-links">
          <a :href="vkUrl" target="_blank" class="nav-socials__block" v-if="$store.getters.language == 'ru'">
            <img :src="require('@/assets/images/help/vk.svg')" alt="">
          </a>
          <a :href="tgUrl" target="_blank" class="nav-socials__block">
            <img :src="require('@/assets/images/help/telegram.svg')" alt="">
          </a>
          <a :href="discordUrl" target="_blank" class="nav-socials__block">
            <img :src="require('@/assets/images/help/dis.svg')" alt="">
          </a>
        </div>
        <div class="footer__partnership">
          <p>{{ $t('partnership.info') }}</p>
          <Button size="md" @click="isShowPartnershipModal = true">{{ $t('partnership.title') }}</Button>
        </div>

        <div class="footer__copyright">ROBUXPIER ©2018-2025</div>
      </div>
    </div>
    <PartnershipModal v-if="isShowPartnershipModal" @close="isShowPartnershipModal = false" />
  </footer>
</template>

<script>
import './footer.scss'
import ipUtil from './../../utils/ip'
import Logo from '@/components/Logo/Logo.vue'
import PartnershipModal from '@/components/Modals/Partnership/PartnershipModal.vue'
import Button from '@/components/Base/Button/Button.vue'
import social from '@/mixins/social'

export default {
  name: 'Footer',
  mixins: [social],
  components: {
    Button,
    Logo,
    PartnershipModal
  },
  data () {
    return {
      isShowPartnershipModal: false
    }
  },
  methods: {
    isCountryFromRu (c) {
      return ipUtil.isCountryInRuRegion(c)
    },
    isCountryRu (c) {
      return ipUtil.isCountryRu(c)
    }
  }
}
</script>
