<template>
  <div class="not-found">
    <div class="wrapper">
      <Navigation />
      <div class="not-found__body">
        <div class="not-found__main">
          <img class="not-found__image" src="../../assets/images/not-found/shark-not-found.svg" alt="shark">
          <div class="not-found__info">
            <div class="not-found__title">{{ $t('notFound.title') }}</div>
            <p class="not-found__description">{{ $t('notFound.description') }}</p>
            <Button left-icon="/logPass/arrow-back.svg" class="not-found__button" size="md" @click="$router.push('/')">{{ $t('notFound.button') }}</Button>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import './notFound.scss'
import Footer from '@/components/Footer/Footer.vue'
import Navigation from '@/components/Navigation/Navigation.vue'
import Button from '@/components/Base/Button/Button.vue'

export default {
  name: 'NotFound',
  components: { Button, Navigation, Footer }
}
</script>
